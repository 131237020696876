<template>
    <h1 class="heading--primary">Zmiana hasła</h1>
    <form method="post">
        <div class="row">
            <div class="col-12 col-sm-8">
                <form-input
                    v-model="form.oldPassword"
                    label="Aktualne hasło"
                    type="password"
                    name="old_password"
                    :error="formErrors.oldPassword"/>
                <form-input
                    v-model="form.newPassword"
                    label="Nowe hasło"
                    type="password"
                    name="new_password"
                    :error="formErrors.newPassword"/>
                <form-input
                    v-model="form.newPasswordConfirmation"
                    label="Powtórz nowe hasło"
                    type="password"
                    name="new_password_confirmation"
                    :error="formErrors.newPasswordConfirmation"/>

                <form-buttons :loading="isLoading" @submitted="changePassword" @cancelled="cancel"/>
            </div>
        </div>
    </form>
</template>

<script setup>
import {reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useAuth} from '@/services/auth/auth';
import useNotifications from '@/composables/useNotifications';

const form = reactive({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
});

const router = useRouter();
const auth = useAuth();
const {showSuccess} = useNotifications();

const formErrors = ref([]);
const isLoading = ref(false)

const changePassword = async () => {
    isLoading.value = true;

    const {isSuccess, errors} = await auth.changePassword(
        form.oldPassword, form.newPassword, form.newPasswordConfirmation
    );

    formErrors.value = errors.value;
    isLoading.value = false;

    if (isSuccess.value) {
        showSuccess('Hasło zostało zmienione.');
        await router.push({name: 'dashboard'});
    }
}

const cancel = () => {
    router.push({name: 'dashboard'});
}

</script>


