export default function useErrors(errors) {
    const getError = (field) => {
        if (errors.value[field] !== undefined) {
            return errors.value[field];
        }

        return null;
    }

    const anyError = () => {
        return true;
    }

    return {getError, anyError}
}