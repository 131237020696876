import useApi from "@/services/api/useApi";

export default function useTwoFactor() {
    const {loading, errors, data, isSuccess, get, post, destroy} = useApi();

    const getQRCode = async () => {
        return await get(`office/twoFactor`);
    }

    const enableTwoFactor = async (oneTimePassword) => {
        return await post('office/twoFactor', {oneTimePassword});
    }

    const disableTwoFactor = async (password) => {
        return await destroy(`office/twoFactor`, {password});
    }

    return {
        data,
        errors,
        loading,
        isSuccess,
        getQRCode,
        enableTwoFactor,
        disableTwoFactor,
    }
}