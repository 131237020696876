<template>
    <h1 class="heading--primary">Uwierzytelnianie dwuetapowe</h1>
    <template v-if="employee.isTwoFactorEnabled">
        <p>Uwierzytelnianie dwuetapowe jest <strong>włączone.</strong> Możesz go wyłączyć podając hasło do konta.</p>
        <form method="post">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <form-input
                        v-model="password"
                        name="password"
                        label="Hasło"
                        type="password"
                        :error="getError('password')"/>

                    <submit-button @click.prevent="disable" :loading="loading" label="Wyłącz"/>
                </div>
            </div>
        </form>
    </template>
    <template v-else>
        <p>Uwierzytelnianie dwuetapowe jest <strong>wyłączone.</strong> Zeskanuj kod QR, a następnie wpisz hasło jednorazowe.</p>
        <div v-html="image"></div>

        <form method="post">
            <div class="row">
                <div class="col-12 col-sm-4">
                    <form-input
                        v-model="oneTimePassword"
                        name="one_time_password"
                        label="Hasło jednorazowe"
                        :error="getError('oneTimePassword')"/>

                    <submit-button @click.prevent="enable" :loading="loading" label="Włącz"/>
                </div>
            </div>
        </form>

    </template>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useAuth} from '@/services/auth/auth';
import useTwoFactor from '@/services/auth/useTwoFactor';
import useErrors from '@/services/api/useErrors';
import SubmitButton from '@/components/form/SubmitButton';
import {useRouter} from 'vue-router';
import useNotifications from '@/composables/useNotifications';

const auth = useAuth();
const router = useRouter();
const {showSuccess} = useNotifications();
const {loading, errors, getQRCode, enableTwoFactor, disableTwoFactor} = useTwoFactor();
const {getError} = useErrors(errors);
const employee = auth.getEmployee();

const image = ref(null);
const oneTimePassword = ref(null);
const password = ref(null);

onMounted(async () => {
    const {data} = await getQRCode()
    image.value = data.value;
});

const enable = async () => {
    const {isSuccess} = await enableTwoFactor(oneTimePassword.value)

    if (isSuccess.value) {
        await auth.fetchEmployee();
        showSuccess('Uwierzytelnianie dwuetapowe zostało włączone.');
        await router.replace({name: 'dashboard'});
    }
}

const disable = async () => {
    const {isSuccess} = await disableTwoFactor(password.value);

    if (isSuccess.value) {
        await auth.fetchEmployee();
        showSuccess('Uwierzytelnianie dwuetapowe zostało wyłączone.');
        await router.replace({name: 'dashboard'});
    }
}

</script>


